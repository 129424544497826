import React, { Component } from "react";
import emailjs from "@emailjs/browser";

export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      message: "",
    };
    this.ref = React.createRef();
  }

  render() {
    return (
      <div className="contact-form">
        <form ref={this.ref} onSubmit={this.sendEmail.bind(this)}>
          <div className="form-item">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              aria-describedby="emailHelp"
              value={this.state.email}
              onChange={this.onEmailChange.bind(this)}
            />
          </div>
          <div className="form-item">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={this.state.name}
              onChange={this.onNameChange.bind(this)}
            />
          </div>
          <div className="form-item">
            <label htmlFor="message">Message</label>
            <textarea
              type="text"
              name="message"
              rows="5"
              placeholder="Message"
              value={this.state.message}
              onChange={this.onMessageChange.bind(this)}
            ></textarea>
          </div>
          <button className="btn btn-primary">Submit</button>
        </form>
      </div>
    );
  }

  sendEmail(e) {
    const form = this.ref.current;
    const serviceId = "service_sk00c48";
    const templateId = "template_iq1g2eh";
    const publicKey = "H_PXiwb450Eq-BR8_";

    e.preventDefault();

    emailjs.sendForm(serviceId, templateId, form, publicKey).then(
      (result) => {
        console.log("Success: " + result.text);
        this.resetForm();
      },
      (error) => {
        console.log("Failed: " + error.text);
      }
    );
  }

  onNameChange(e) {
    this.setState({ name: e.target.value });
  }

  onEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  onMessageChange(e) {
    this.setState({ message: e.target.value });
  }

  resetForm() {
    this.setState({ email: "", name: "", message: "" });
  }
}
