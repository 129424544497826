import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (
      <div className="about">
        <h3>About</h3>
        <h6>
          There are all kinds of iOTC products on the shelves, and yet many of
          them people are not very familiar with until they need to purchase
          one. There are so many looked like ingredients, sounds like brand or
          generic , different forms of drugs, multiple use of medications, plus
          homeopathic. herbal supplements, vaccines, and so on. Not to mention
          there are new OTC products and new vaccines put on the market every
          year. Between pharmacy shelves and isles our users deserve to know
          overall and in detail about those commonly used OTC products to make
          educated purchases. iOTC is a reference app to provide useful
          information on iOTC products as a pharmacy shopping guide. It has
          twelve tables contents:
        </h6>
        <ol>
          <li>Vitamin & Mineral </li>
          <li>Herbal supplements </li>
          <li>Non prescription medications</li>
          <li>Feminine & Cosmetics</li>
          <li>Vaccines</li>
          <li>Pediatric OTC</li>
          <li>Diabetic Health Care</li>
          <li>New Drugs</li>
          <li>Homeopathic</li>
          <li>Seasonal OTC</li>
          <li>Home Tests & devices</li>
          <li>Infant Care </li>
        </ol>
        <h6>
          In order to be user friendly. The contents open up like a menu, and it
          tells what health conditions may be related that products may be used
          for. Also It gives a list of vaccines in pharmacies that are available
          to the public. Plus, this app will give users up to 3 pharmacist’s
          recommended brand choices, so users can shop like a pro, ask questions
          like a pro. In addition, his app provides some information about newly
          FDA approved prescription, non prescription drugs and new vaccines.
          Using layman terms and menu like displays this app is easy like ABC
          for everyone to use, whether you are a first time pharmacy shopper or
          professionals. Pharmacy is in duo languages, English and Chinese.
          Spanish and English.
        </h6>
      </div>
    );
  }
}
